import { createHttpEndpoint } from '../../utils'

/**
 * Returns addresses predictions based on the provided input
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-addresses-api/definition#tag/autocomplete/operation/GetAddressAutocomplete}
 */
export const getAutocomplete = createHttpEndpoint({
  method: 'GET',
  operationId: 'GetAddressAutocomplete',
  path: '/bm/shipping/v1/address-autocomplete',
})

/**
 * Returns address details based on the provided id
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-addresses-api/definition#tag/autocomplete/operation/GetAddressDetails}
 */
export const getAddressDetails = createHttpEndpoint({
  method: 'GET',
  operationId: 'GetAddressDetails',
  path: '/bm/shipping/v1/address-details',
})

export type AucompleteHit = {
  label: string
  placeId: string
  highlights: { offset: number; length: number }[]
}

export type AucompleteHits = {
  results: AucompleteHit[]
  sessionToken: string
  requestId: string
}

export type AddressDetails = {
  city: string
  country: string
  postalCode: string
  requestId: string
  stateProvince: string
  street: string
}

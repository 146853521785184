import type { Country } from '@backmarket/http-api'

import { STATES } from '../../constants'

export function stateNameToCode(
  stateName: string,
  countryCode: Country,
): string {
  const states = STATES[countryCode] || []
  const state = states.find(
    ({ label }) => label.toLowerCase() === stateName.toLowerCase(),
  )

  return state ? state.value : ''
}

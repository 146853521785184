import {
  type Country,
  Locale,
  MarketCountryCode,
  addressAPI,
} from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { FeatureCode } from '../../types'
import type {
  BmPlacesDetails,
  BmPlacesDetailsRequest,
  BmPlacesOption,
  BmPlacesOptionsRequest,
  Option,
} from '../../types/bmPlaces'

import { stateNameToCode } from './stateNameToCode'

const mapFromBmPlacesHighlightToOption = (
  results: BmPlacesOption[],
): Option[] =>
  results.map(({ label, highlights, placeId }) => {
    const labels = []
    let lastOffset = 0

    highlights.forEach(({ length, offset }) => {
      const sublabel = label.substring(lastOffset, offset)
      lastOffset = offset + length
      const highlight = label.substring(offset, lastOffset)
      labels.push({ text: sublabel, class: 'body-1-light' })
      labels.push({ text: highlight, class: 'body-1-bold' })
    })

    labels.push({
      text: label.substring(lastOffset, label.length),
      class: 'body-1-light',
    })

    return { labels: labels.filter((v) => !isEmpty(v.text)), placeId }
  })

/**
 * Places API client through badoom.
 * https://github.com/BackMarket/api-specs/blob/master/reference/shipping/addresses-api.yaml
 *
 * The client is designed to focus on one single search at a time: any new
 * search request will cancel previous ones. So it's intended to be instanciated
 * for each source of input (typically, user input).
 */
export class BmPlaces {
  private country: Country | MarketCountryCode

  private httpFetch: typeof $httpFetch

  private language: Locale

  private sessionToken: string | null

  private pendingSearch: {
    request: BmPlacesOptionsRequest
    cancelController: AbortController
  } | null

  constructor({
    country,
    language,
    httpFetch,
  }: {
    country: Country | MarketCountryCode
    language: Locale
    httpFetch: typeof $httpFetch
  }) {
    this.country = country
    this.httpFetch = httpFetch
    this.language = language
    this.sessionToken = null
    this.pendingSearch = null
  }

  private search({
    query,
    featureCode,
  }: {
    query: string
    featureCode: FeatureCode
  }): {
    request: BmPlacesOptionsRequest
    cancelController: AbortController
  } {
    const cancelController = new AbortController()

    const request = this.httpFetch(addressAPI.getAutocomplete, {
      queryParams: {
        country: this.country,
        input: query,
        language: this.language,
        featureCode,
        ...(this.sessionToken && { sessionToken: this.sessionToken }),
      },
      signal: cancelController.signal,
    }) as BmPlacesOptionsRequest

    return { request, cancelController }
  }

  async getOptions({
    query,
    featureCode,
  }: {
    query: string
    featureCode: FeatureCode
  }): Promise<Option[]> {
    // Cancel pending request, if any
    if (this.pendingSearch) {
      this.pendingSearch.cancelController.abort()
      this.pendingSearch = null
    }

    this.pendingSearch = this.search({ query, featureCode })

    try {
      const response = await this.pendingSearch?.request
      this.pendingSearch = null

      if (response?.sessionToken) {
        this.sessionToken = response.sessionToken
      }

      return response ? mapFromBmPlacesHighlightToOption(response.results) : []
    } catch (error) {
      this.pendingSearch = null
      throw error
    }
  }

  async getDetails(
    { placeId }: Option,
    featureCode: FeatureCode,
  ): Promise<BmPlacesDetails> {
    if (this.pendingSearch) {
      this.pendingSearch.cancelController.abort()
      this.pendingSearch = null
    }

    const response = await (this.httpFetch(addressAPI.getAddressDetails, {
      queryParams: {
        featureCode,
        placeId,
        language: this.language,
        ...(this.sessionToken && { sessionToken: this.sessionToken }),
      },
    }) as BmPlacesDetailsRequest)

    const stateOrProvince = response.stateProvince
      ? stateNameToCode(response.stateProvince, response.country)
      : ''

    return {
      ...response,
      ...insertIf(Boolean(stateOrProvince), { stateOrProvince }),
      city: response?.city || '',
      country: response?.country || '',
      postalCode: response?.postalCode || '',
      street: response?.street || '',
      street2: response?.street2 || '',
    }
  }
}
